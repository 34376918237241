(function($) {
  // Apply To All

  $('.apply-to-all').click(function(){
    var start_time = $("input[name='monday_start']").val();
    var end_time = $("input[name='monday_end']").val();

    $("input[name*='start']").each(function(){
      $(this).val(start_time);
    });

    $("input[name*='end']").each(function(){
      $(this).val(end_time);
    });
  });

  // Show input text if other
  $("li.active>input").change(function(){
    if($(this).val() == "other") {
      $("#other_text").attr('style', 'display: block');
    } else {
      $("#other_text").attr('style', 'display: none');
    }
  });

  $("select[name*='category']").change(function(){
    if($(this).val() == "other") {
      $("#other_text").attr('style', 'display: block');
    } else {
      $("#other_text").attr('style', 'display: none');
    }
  });

  $("select[id^='multiselect-']").multiselect();

  $("select[name*='community']").change(function(){
    if($(this).val() == "other") {
      $("#community_service_other").attr('style', 'display: block');
    } else {
      $("#community_service_other").attr('style', 'display: none');
    }
  });

})(jQuery);
